import React from 'react';

import { IconSpinner, LayoutComposer } from '../../components/index.js';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import css from '../SearchPage/SearchPage.module.css';

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

const LandingPageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    currentPage,
    pageId,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  const { sections = [] } = pageAssetsData || {};

  return (
    <div className={css.landingSearchHero}>
      {sections.length === 0 && inProgress ? (
        <LoadingSpinner />
      ) : (
        <SectionBuilder sections={sections} options={options} pageId={pageId} />
      )}
    </div>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

export default LandingPageBuilder;
